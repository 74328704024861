.main_info {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 10px;

  .info_peice {
    i {
      position: relative;
      top: 3px;
    }
    span {
      font-size: 18px;
      font-weight: bold;
      margin-left: 10px;
    }
  }
}

.account {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  width: 100%;
  height: 100%;

  h4 {
    margin-bottom: 20px;
  }

  .account__input_group {
    margin-bottom: 20px;

    .account__input_button {
      padding-right: 15px;
      padding-left: 15px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  
      i {
        position: relative;
        top: 2px;
      }
    }
  
    .account__input {
      padding-top: 8px;
      padding-bottom: 8px;
      height: auto;
  
      &:focus {
        border-color: #C7D0D9;
        outline-color: #C7D0D9;
      }
    }
  }

  .privacy_section {
    width: 50%;
  }

}
