.notValid {
  padding: 15px;
  text-align: center;

  i {
    font-size: 60px;
    color: #b60000;
  }

  h3 {
    color: #b60000;
  }

  a {
    display: block;
    margin: 30px 20px 0;
    padding: 10px 30px;
    color: #aaa;
    background: #e7e7e7;
    border-radius: 10px;
    font-size: 18px!important;
    text-transform: capitalize;
  }
}

.loading {
  text-align: center;
  padding: 25px 0 20px;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  margin: 0 auto;
  background: #e7e7e7;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
