@import "../../styles/helpers";

.tableTitle {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px;

  img {
    margin-left: 16px;
  }
}

.transactionTitleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 24px 13px 16px;
}

.widgetContentBlock {
  margin: 0 0 24px;
}

.request_card {
  margin-bottom: 5px;
  background-color: #6b859e;
  border-color: #f1f1f1!important;
}

.request_card__body {
  h3 {
    font-family: Gilroy;
    font-weight: bold;
  }
  p {
    font-family: Gilroy;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 13px 24px 13px 16px;
  border-radius: 8px!important;
  background-color: $base-light-gray!important;
  border: none!important;

  @media screen and (max-width: 767px) {
    padding: 10px!important;
  }

  & > div {
    text-align: center;
    flex: 2;
    font-size: 14px;
    font-weight: bold;

    &:nth-of-type(3) {
      span {
        padding: 7px 20px;
        background-color: #e7e7e7;
        font-weight: bold;
        font-family: GilroyMedium;
        border-radius: 35px;
        position: relative;
        top: 3px;
        right: 5px;
      }
      i {
        position: relative;
        top: 3px;
        right: 5px;
      }
    }

    &:last-of-type {
      flex: 1;
      i {
        font-size: 20px;
        cursor: pointer;
        position: relative;
        top: 3px;
        right: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        color: #6b859e;
        transition: all 0.3s ease-in-out;
        background: #d5e3f1;
        border-radius: 5px;
        padding: 5px;
        &:hover {
          color: #a9b4be;
        }

        @media screen and (max-width: 440px) {
          margin-right: 0px;
        }
      }
    }
  }

  &.head {
    div, li {
      text-align: center;
      font-family: GilroyMedium;
      font-weight: bold;
      font-size: 15px;
    }
    li {
      font-size: 14px;
    }
  }

  &.no_requests {
    p {
      width: 100%;
      font-size: 20px;
      text-align: center;
      color: #6b859e;
      font-weight: bold;
      font-family: GilroyMedium;
      padding: 35px 0;
    }
  }
}

.image {
    width: 32px;
    height: 32px;
}

.checkboxCol {
  width: 5%;
}

.nameCol {
  width: 15%;
}

.top_panel {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
  
  .all_requests {
    display: flex;
    padding: 10px 15px;

    i {
      margin-right: 10px;
    }

    span {
      padding: 5px 15px;
      background-color: #f7f8fb;
      border-radius: 5px;
      font-weight: bold;
      margin-left: 10px;
      color: #6b859e;
      font-family: GilroyMedium;
    }

    @media screen and (max-width: 767px) {
      padding: 0 5px;
      font-size: 13px;

      p {
        width: 100%;
        text-align: center;
      }

      span {
        margin-top: 10px;
        margin-left: 0px;
      }
    
      i {
        display: none;
      }
    }
  }
}

.modal__input_group {
  margin-bottom: 20px;

  .modal__input_button {
    padding-right: 15px;
    padding-left: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    i {
      position: relative;
      top: 2px;
    }
  }

  .modal__input {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;

    &:focus {
      border-color: #C7D0D9;
      outline-color: #C7D0D9;
    }
  }
}